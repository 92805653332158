import React from 'react';
import loadable from '@loadable/component';

import { RAISE_BUG_GH } from 'constants/navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignIn,
  faPenSquare,
} from '@fortawesome/pro-light-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

import { useIconOverRide, useReportBugHeadline } from './styles';

const Grid = loadable(() => import('@material-ui/core/Grid'));
const Typography = loadable(() => import('@material-ui/core/Typography'));
const List = loadable(() => import('@material-ui/core/List'));
const ListItem = loadable(() => import('@material-ui/core/ListItem'));
const ListItemIcon = loadable(() => import('@material-ui/core/ListItemText'));
const ListItemText = loadable(() => import('@material-ui/core/ListItemText'));

const LandingHeadlineIconList = () => {
  const styles = useReportBugHeadline();
  const iconOveride = useIconOverRide();

  return (
    <Grid spacing={1}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
     >
      <Grid item xs={12} sm={8} md={8} lg={6}>
          <List>
            <ListItem >
              <ListItemIcon classes={iconOveride}>
                <FontAwesomeIcon icon={faGithub} size="2x" className={styles.icon}/>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="h6" component="p" className={styles.listItemText}>
                    {'Navigate to '}
                    <a
                      href={RAISE_BUG_GH}
                      rel="noopener noreferrer"
                      target="_blank"
                      className={styles.linkNoStyle}> Total Loyalty Support</a>
                  </Typography>
                } />
            </ListItem>
            <ListItem>
              <ListItemIcon classes={iconOveride}>
                <FontAwesomeIcon icon={faSignIn} size="2x" className={styles.icon}/>
              </ListItemIcon>
              <ListItemText
                primary={
                <Typography variant="h6" component="p" className={styles.listItemText}>
                  Log in or create a GitHub account (its free)
                </Typography>
              } />
            </ListItem>
            <ListItem>
              <ListItemIcon classes={iconOveride}>
                <FontAwesomeIcon icon={faPenSquare} size="2x" className={styles.icon}/>
              </ListItemIcon>
              <ListItemText
                primary={
                <Typography variant="h6" component="p" className={styles.listItemText}>
                 Create a issue providing as much information as possible
                </Typography>
              } />
            </ListItem>
          </List>
          </Grid>
        </Grid>
  );
};

export default LandingHeadlineIconList;
